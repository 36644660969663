<template>
  <div id="app">
    <router-view/>
    <div v-if="deferredPrompt || updateExists" class="fixed right-0 bottom-0 z-50">
      <div v-if="deferredPrompt" class="p-4 m-4 bg-white rounded-xl border shadow-lg sm:max-w-sm sm:left-auto">
        <h3 class="my-0 ml-1 text-lg font-medium">Install Desktivity</h3>
        <p class="mt-2 ml-1 text-gray-500">Installing uses almost no storage and provides quick way to return to this app.</p>
        <div class="flex justify-end mt-4">
          <button class="py-2 px-6 mr-2 text-gray-500 bg-gray-50 rounded" @click="dismiss">Not Now</button>
          <button class="py-2 px-7 font-medium text-green-600 bg-green-50 rounded" @click="install">Install</button>
        </div>
      </div>
      <div v-if="updateExists" class="p-4 m-4 bg-white rounded-xl border shadow-lg sm:max-w-sm sm:left-auto">
        <div class="flex justify-end">
          <p class="flex-1 mt-2 mr-10 ml-2 text-gray-700">New version available!</p>
          <button class="py-2 px-6 font-medium text-green-600 bg-green-50 rounded" @click="refreshApp">Update</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import update from '@/mixins/update'

export default {
  name: 'App',
  mixins: [update],
  data() {
    return {
      deferredPrompt: null
    }
  },
  created() {
    const installHandler = e => {
      e.preventDefault()
      this.deferredPrompt = e
    }
    window.addEventListener('beforeinstallprompt', installHandler)
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null
    })
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeinstallprompt', installHandler)
    })
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null
    },
    async install() {
      this.deferredPrompt.prompt()
    }
  }
}
</script>

<style lang="scss">
@import 'assets/styles/main.scss';
</style>
